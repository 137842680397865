import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import Loader from "./Loader";
import MainLayout from "./layouts/MainLayout";
import AdminLayout from "./layouts/AdminLayout";
import ClientLayout from "./layouts/ClientLayout";
import Login from "./Login";
import SwapsS from "./screens/SwapsS";
import TokensS from "./screens/TokensS";
import DepositS from "./screens/DepositS";
import WithdrawalS from "./screens/WithdrawalS";
import PartnersS from "./screens/PartnersS";
import OrdersS from "./screens/OrdersS";
import ControlS from "./screens/ControlS";
import PrivateRoute from "./router/PrivateRoute";
import Pairs from "./screens/Pairs";

const HomePage = lazy(() => import("../pages/HomePage"));
const Admin = lazy(() => import("../pages/Admin"));
const About = lazy(() => import("../pages/About"));
const Contacts = lazy(() => import("../pages/Contacts"));
const Exchanges = lazy(() => import("../pages/Exchanges"));
const HowToUse = lazy(() => import("../pages/HowToUse"));
const Privacy = lazy(() => import("../pages/Privacy"));
const Terms = lazy(() => import("../pages/Terms"));
const SelectCust = lazy(() => import("./SelectCust"));
const Dashboard = lazy(() => import("./screens/Client/Dashboard/Dashboard"));
const History = lazy(() => import("./screens/Client/History/History"));
const Settings = lazy(() => import("./screens/Client/Settings/Settings"));

const AppRouter = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<About />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="exchange" element={<Exchanges />} />
          <Route path="howtouse" element={<HowToUse />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="select" element={<SelectCust />} />
        </Route>

        <Route path="admin" element={<AdminLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="swaps" element={<PrivateRoute><SwapsS /></PrivateRoute>} />
          <Route path="tokens" element={<PrivateRoute><TokensS /></PrivateRoute>} />
          <Route path="pairs" element={<PrivateRoute><Pairs /></PrivateRoute>} />
          <Route path="deposit" element={<PrivateRoute><DepositS /></PrivateRoute>} />
          <Route path="withdrawal" element={<PrivateRoute><WithdrawalS /></PrivateRoute>} />
          <Route path="partners" element={<PrivateRoute><PartnersS /></PrivateRoute>} />
          <Route path="orders" element={<PrivateRoute><OrdersS /></PrivateRoute>} />
          <Route path="control" element={<PrivateRoute><ControlS /></PrivateRoute>} />
        </Route>

        <Route path="client" element={<ClientLayout />}>
          <Route path="dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="history" element={<PrivateRoute><History /></PrivateRoute>} />
          <Route path="settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRouter;